@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200&display=swap');

body {
  font-family: 'Jost', sans-serif !important;
}

.navbar-toggler span {
  display: block;
  background-color: #101010;
  height: 3px;
  width: 25px;
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
  border-radius: 2px;
  left: 0;
  opacity: 1;
  transition: all 0.35s ease-out;
  transform-origin: center left;
}

.navbar-toggler span:nth-child(1) {
  margin-top: 0.3em;
  transition: all 0.4s;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  transform: translate(15%, -33%) rotate(45deg);
  transition: all 0.4s;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  opacity: 0;
  transition: all 0.4s;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  transform: translate(15%, 33%) rotate(-45deg);
  transition: all 0.4s;
}

.navbar-toggler span:nth-child(1) {
  transform: translate(0%, 0%) rotate(0deg);
  transition: all 0.4s;
}

.navbar-toggler span:nth-child(2) {
  opacity: 1;
  transition: all 0.4s;
}

.navbar-toggler span:nth-child(3) {
  transform: translate(0%, 0%) rotate(0deg);
  transition: all 0.4s;
}

.dropdown-toggle[aria-expanded="true"]:after {
  transform: rotate(180deg);
}

.dropdown-toggle:after {
  transition: 0.3s;
}

.progress-bar {
  letter-spacing: 1px;
}

#dropdwnBg {
  border: 1px solid #1e99b9;
  background: transparent;
  padding: 0.5rem;
}

.dropdown-item {
  padding: 0.5rem;
  text-align: center;
  transition: 0.5s ease;
  color: white;
}

.dropdown-item:hover {
  color: black;
  background-color: #1e99b9;

}

.active_class {
  color: rgb(26, 255, 255);
}

.navbar-brand {
  font-size: 1.6rem;
  font-weight: 700;
  color: white;
}

.menuItem {
  font-size: 1.2rem;
  font-weight: 500;
}

#container01 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

@media screen and (max-width:705px) {
  .content {
    margin-right: 10px !important;
    line-height: 1.7rem;
  }
}

.filepond--credits {
  display: none;
}

#upload {
  margin-left: 10px;
}

.weatherMain {
  padding: 9rem;
  text-align: center;
}

@media screen and (max-width: 991px) {
  .weatherMain {
    padding: 25px;
    margin-top: 4rem;
  }

  .menuItem {
    margin: 3px auto;
    text-align: center;
  }
}

@media screen and (max-width: 570px) {
  .weatherSrch {
    width: 100% !important;
  }
}

.weatherSrch {
  width: 35%;
  border: 1px solid rgb(209, 209, 209);
  border-top: none;
  border-left: none;
  border-right: none;
  background: transparent;
  border-radius: 0;
  color: aliceblue;
  outline: transparent;
}

.weatherChild01 {
  padding-bottom: 2rem;
  background-color: #3e2c5469;
  padding-top: 1px;
  border-radius: 13px;
  margin-top: 1.2rem;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.274);
  color: white;
}

.weatherChild01 h1 {
  font-weight: 700;
  font-size: 2.5rem;
  text-transform: capitalize;
}

.weatherChild01 h2 {
  font-weight: 700;
  font-size: 3rem;
}

/* APP */
.ce-chat-list {
  background-color: rgb(240, 240, 240) !important;
}

.ce-chats-container {
  background-color: rgb(240, 240, 240) !important;
}

.ce-active-chat-card {
  background-color: #8dffa887 !important;
  ;
  border-radius: 0px !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.224) !important;
  margin-top: 15px;
  border-radius: 11px !important;
}

.ce-chat-card {
  border-radius: 11px !important;
  color: black !important;
  margin-top: 10px !important;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.224) !important;
}

.ce-chat-title-text {
  color: rgb(0, 0, 0) !important;
}

.ce-chat-subtitle-text {
  color: black !important;
}

.ce-chat-subtitle-date {
  color: black !important;
}

.ce-chat-form-container {
  padding-bottom: 20px !important;
}

.ce-text-input {
  border-radius: 6px !important;
  border: 1px solid #3b2a50 !important;
}

.ce-primary-button {
  border-radius: 50px !important;
  background-color: #8dffa887 !important;
  ;
  position: relative;
  color: black !important;
  bottom: 1px;
}

.ce-danger-button {
  background-color: white !important;
  border-radius: 22px !important;
}

.ce-settings {
  background-color: rgb(240, 240, 240) !important;
  border-left: 1px solid rgb(224, 224, 224) !important;
}

.ce-autocomplete-input {
  border-radius: 6px !important;
  border: 1px solid #3b2a50 !important;
}

.ce-autocomplete-options {
  border-radius: 6px !important;
  border: 1px solid #3b2a50 !important;
  background-color: white !important;
}

.ce-chat-settings-container {
  padding-top: 12px !important;
}

.ce-chat-avatars-row {
  display: none !important;
}

/* CUSTOM FEED */

.chat-feed {
  height: 100%;
  width: 100%;
  overflow: scroll;
  background-color: rgb(240, 240, 240);
}

.chat-title-container {
  width: calc(100% - 36px);
  padding: 18px;
  text-align: center;
}

.chat-title {
  color: #7554a0;
  font-weight: 800;
  font-size: 24px;
}

.chat-subtitle {
  color: #7554a0;
  font-weight: 600;
  font-size: 12px;
  padding-top: 4px;
}

.ce-my-message-bubble-row {
  margin-right: 11px !important;
}

.ce-my-message-bubble {
  background-color: #8dffa887 !important;
  margin-top: 8px;
  color: black !important;
}

.ce-text-input {
  border: 1px solid #8dffa887 !important;
  border-radius: 50px !important;
  padding: 20px !important;
}

.ce-my-message-timestamp {
  color: #8dffa887 !important;
}

.ce-chat-settings-container {
  padding-bottom: 12px !important;
}

.ce-chats-container {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

.ce-person-container {
  padding: 13px !important;
  border-bottom: 1px solid rgb(197, 197, 197);
}

.ce-person-title-container {
  background-color: #8dffa887 !important;
  border-radius: 50px;
  padding: 3px;
  color: black !important;
  margin-top: 10px;
}

#appNav {
  color: black !important;
}

.anticon-menu {
  color: #333 !important;
}

#ce-feed-container {
  background-color: whitesmoke !important;
}

.ce-chat-list {
  background-color: rgb(240, 240, 240) !important;
  border-right: 1px solid rgb(224, 224, 224) !important;
}

.ce-chats-container {
  background-color: rgb(240, 240, 240) !important;
  padding: 18px;
}

.anticon-setting {
  color: #333 !important;
}

.ce-chat-form-container {
  color: black !important;
  border-radius: 11px;
}

.ce-person-text {
  color: black !important;
  font-weight: 500;
}

.anticon-close {
  color: black !important;
  left: 13px !important;
}

.ce-photo-thumbnail {
  margin-left: 10px !important;
  border: none !important;
  padding: 0 !important;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.212);
}

.ce-my-message-images-container {
  margin-top: 8px !important;
  padding: 15px;
}

#ce-delete-chat-button {
  border: 1px solid #3ccb5d !important;
}

.ce-photo-feed {
  padding: 13px !important;
}

.ce-photo-title-container {
  background-color: #8dffa887 !important;
  border-radius: 50px;
  padding: 3px;
  color: black !important;
  margin-top: 10px;
}

#ce-options-drop-down {
  background-color: #8dffa887 !important;
  border-radius: 50px !important;
  padding: 3px !important;
  color: black !important;
  margin-top: 10px !important;
}

#ce-add-username-input {
  border-radius: 50px !important;
  border: 1px solid #8dffa887 !important;
  padding: 20px !important;
  font-family: system-ui !important;
  margin-bottom: 7px;
}

#ce-username-option-username {
  border-bottom: 1px solid black !important;
}

.ce-autocomplete-options {
  padding: 13px !important;
  border: 1px solid #8dffa887 !important;
}

.ce-autocomplete-close {
  position: relative;
  top: 14px;
}

.ce-settings {
  font-family: system-ui !important;
}

.ce-person-text {
  padding-left: 68px !important;
}

#ce-send-message-button {
  background-color: #8dffa887 !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.anticon-arrow-up {
  color: #333 !important;
}

.ce-their-message-bubble {
  margin-left: 8px !important;
}

.ce-avatar {
  margin-left: 11px !important;
}

.message-row {
  float: left;
  width: 100%;
  display: flex;
  margin-left: 18px;
}

.message-block {
  width: 100%;
  display: inline-block;
}

.message-avatar {
  width: 44px;
  height: 44px;
  border-radius: 22px;
  color: white;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 48px;
}

.message {
  padding: 12px;
  font-size: 16px;
  border-radius: 6px;
  max-width: 60%;
}

.message-image {
  margin-right: 18px;
  object-fit: cover;
  border-radius: 6px;
  height: 30vw;
  /* width: 30vw; */
  max-height: 200px;
  max-width: 200px;
  min-height: 100px;
  min-width: 100px;
}

.read-receipts {
  position: relative;
  bottom: 6px;
}

.read-receipt {
  width: 13px;
  height: 13px;
  border-radius: 13px;
  margin: 1.5px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px;
}

.message-form-container {
  position: absolute;
  bottom: 0px;
  width: calc(100% - 36px);
  padding: 18px;
  background-color: rgb(240, 240, 240);
}

.message-form {
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid #3b2a50;
  background-color: white;
}

.message-input {
  height: 40px;
  width: calc(100% - 132px);
  background-color: white;
  border: 1px solid white;
  padding: 0px 18px;
  outline: none;
  font-size: 15px;
}

.image-button {
  cursor: pointer;
  padding: 0px 12px;
  height: 100%;
}

.send-button {
  height: 42px;
  background-color: white;
  border: 1px solid white;
  padding: 0px 18px;
  cursor: pointer;
}

.send-icon {
  top: 1px;
  position: relative;
  transform: rotate(-90deg);
}

.picture-icon {
  top: 1px;
  position: relative;
  font-size: 14px;
}

/* FORM STYLES */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 62, 5%;
}

.wrapper {
  height: 100vh;
  width: 100%;
  background: rgba(47, 255, 99, 0.541);
  display: flex;
  justify-content: center;
  align-items: center;
}

#wrap-2 {
  width: 100%;
  background: rgba(47, 255, 99, 0.541);
  height: fit-content;
}

#wrap-2 h2 {
  margin-bottom: 0 !important;
}

.input {
  color: #333;
  font-size: 15px;
  margin: 0 auto;
  padding: 12px 14px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  border: none;
  width: 100%;
  display: block;
  border-bottom: 0.3rem solid transparent;
  transition: all 0.3s;
  outline: none;
  margin-bottom: 25px;
}

.form {
  width: 400px;
}

.title {
  text-align: center;
  color: rgb(40, 40, 40);
  margin-bottom: 30px;
  width: 100%;
  font-size: 2.3em;
  ;
}

.button {
  border-radius: 5px;
  border: 1px solid black;
  background: transparent;
  color: black;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
  padding: 11px;
  width: 270px;
  transition: all 0.4s;
  cursor: pointer;
  margin: 5px;
  font-weight: 500;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.4s;
}

.button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: -2px;
  right: -20px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 25px;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
}

.button:hover {
  background: whitesmoke;
  border: 1px solid whitesmoke;
  color: black;
}

.anticon {
  vertical-align: 2px !important;
}

/* Portfolio */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

#myName {
  color: #1e99b9;
  letter-spacing: 2px;
}

.mainTab {
  padding: 3.5rem;
  margin-top: 2rem;
}

#heroIcon {
  animation: picMove 2s ease-in-out infinite alternate-reverse both;
}

@keyframes picMove {
  0% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(-10px);
  }
}

@-webkit-keyframes picMove {
  0% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(-10px);
  }
}

#wrap-2 h4 {
  width: 50%;
  margin: auto;
  line-height: 2.5rem;
}

@media screen and (max-width: 991px) {
  .mainTab {
    padding: 2.5rem;
  }

  #picSection {
    display: none;
  }

  #wrap-2 h4 {
    width: 100% !important;
    text-align: left;
    margin-top: 2rem;
  }
}

.mainTab h3 {
  color: white;
}

.mainTab h4 {
  letter-spacing: 2px;
}

.mainTab p {
  line-height: 1.7rem;
  letter-spacing: 1px;
}

.home-section {
  width: 100%;
  background: linear-gradient(90deg, black 70%, #1e99b9 30%);
  ;
}

#navbar {
  background: transparent;
}

#logo {
  margin-left: 30px;
  font-size: 28px;
  font-weight: 600 !important;
  color: #1e99b9;
  letter-spacing: 2px;
}

#navbar-nav {
  margin-left: 50px;
  margin-top: 6px;
}

#rmvXtr {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

#heading01 {
  font-size: 30px;
}

@media screen and (max-width: 991px) {
  #navbar-nav {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 1rem;
    text-align: center;
  }

  #logo {
    margin-left: 10px !important;
  }

  #container02 {
    width: 100% !important;
  }

  #btnMargin02 {
    margin: 25px auto;
    text-align: center;
    display: block;
  }

  #services h3 {
    margin-left: 2rem;
  }
}

.textWhite {
  background-color: white !important;
}

.paddingFix {
  padding: 3rem;
}

@media screen and (max-width: 735px) {
  #heading01 {
    font-size: 26px !important;
    margin-top: 4rem !important;
  }

  .paddingFix {
    padding: 16px;
  }
}

@media screen and (max-width: 524px) {
  #heading01 {
    font-size: 21px !important;
    text-align: left !important;
  }
}

@media screen and (max-width: 400px) {
  #heading01 {
    font-size: 18px;
    line-height: 1.8rem;
  }
}

@media screen and (max-width: 500px) {
  #btnMargin button {
    margin-left: auto !important;
    margin-right: auto !important;
    display: block !important;
  }
}

#container02 {
  width: 50%;
}

.menuLink {
  margin-left: 10px;
  font-weight: bold;
  color: white;
  font-size: 16px;
  text-shadow: 1px 1px 1px black;
  letter-spacing: 2px;
  transition: 0.5s ease;
}

.menuLink:hover {
  color: #1e99b9;
}

#icon01 {
  background-color: white;
}

#icon02 {
  background-color: white;
}

#icon03 {
  background-color: white;
}

#btncv {
  background: transparent;
  border: 2px solid black;
  font-weight: bold;
  letter-spacing: 2px;
  border-radius: 5px;
  cursor: pointer;
}

.btncv {
  margin-left: auto;
}

@media screen and (max-width: 991px) {
  #btncv {
    border: 2px solid #1e99b9;
    color: #1e99b9;
    margin-left: 20px;
  }
}

.home {
  width: 100%;
  height: 91.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  z-index: 0;
}

.home .img {
  flex: 1 1 400px;
}

.home .img img {
  width: 76%;
  margin-left: 30px;
}

.home .content {
  flex: 1 1 400px;
  margin-left: 45px;
}

.home .content h5 {
  color: white;
  letter-spacing: 3px;
  font-size: 16px;
  font-weight: bold;
}

.home .content h3 {
  color: white;
  font-size: 50px;
}

.home .content h3 span {
  color: #1e99b9;
  font-weight: bold;
}

.changecontent::after {
  content: ' ';
  color: #1e99b9;
  font-weight: bold;
  font-size: 40px;
  margin-right: auto;
  animation: changetext 8s infinite linear;
}

@keyframes changetext {
  0% {
    content: "Web Developer";
  }

  25% {
    content: "Front-End Developer";
  }

  50% {
    content: "ReactJs Developer";
  }

  75% {
    content: "UI/UX Developer";
  }

  100% {
    content: "Graphic Designer";
  }
}

.home .content p {
  color: white;
  font-weight: bold;
  letter-spacing: 2px;
}

#btn1 {
  padding: 7px;
  text-decoration: none;
  letter-spacing: 2px;
  border-radius: 5px;
  color: #1e99b9;
  border: 2px solid #1e99b9;
  background: transparent;
  margin-top: 10px;
  line-height: 3rem !important;
  font-weight: bold;
  transition: 0.5s ease;
  cursor: pointer;
}

#btn1:hover {
  background-color: #1e99b9;
  color: black;
}

@media screen and (max-width: 844px) {
  .home-section {
    background: black;
  }

  .home .content {
    margin-top: 50px;
  }

  #navbar {
    background: black;
  }
}

@media screen and (max-width: 650px) {


  .img img {
    margin-top: 30px;
  }
}

@media screen and (max-width: 280px) {
  #portfolio h1 {
    padding-top: 100px;
  }
}

#about {
  width: 100%;
  background-color: #060606;
  display: flex;
  align-items: center;
}

.card {
  margin-bottom: 3rem;
}

#marginCard {
  margin-top: 8rem;
  margin-bottom: 5rem;
}

#about .card img {
  background-color: #1e99b9;
  border: none;
  outline: none;

}

#about h3 {
  color: white;
  border-bottom: 2px solid #1e99b9;
  width: 145px;
  padding-bottom: 3px;
}

#about h5 {
  color: white;
  font-weight: bold;
}

#about p {
  color: white;
  font-size: 15px;
}

#about h2 {
  color: white;
  font-size: 17px;
}

#about h2 span {
  font-size: 15px;
  color: #1e99b9;
  margin-left: 10px;
  margin-top: 10px;
}

#btncv2 {
  padding: 7px;
  border-radius: 5px;
  margin-top: 10px;
  text-decoration: none;
  background: transparent;
  border: 2px solid white;
  color: white;
  transition: 0.5s ease;
  cursor: pointer;
  line-height: 4rem !important;
}

#btncv2:hover {
  background-color: #1e99b9;
  border: 2px solid #1e99b9;
  color: white;
}

@media screen and (max-widht: 987px) {
  #about p {
    font-size: 10px;
  }

  #about {
    margin-top: 1rem !important;
  }

  .card {
    margin-bottom: 1rem !important;
  }
}

@media screen and (max-widht: 767px) {
  #about p {
    font-size: 17px;
  }

}

@media screen and (max-widht: 500px) {}

#services {
  width: 100%;
  background-color: #0b0b0b;
}

#cardMargin2 {
  padding: 2rem;
}

#services h3 {
  width: 130px;
  padding-top: 130px;
  padding-bottom: 3px;
  color: white;
  border-bottom: 2px solid #1e99b9;
}

#services .card {
  transition: 0.5s ease-in-out;
  padding: 1.4rem;
  background: transparent;
  color: white;
  border: 1px solid #1e99b9;
}

#services .card:hover {
  border: 1px solid #1e99b9;
  background-color: #1e99b9;
  color: white;
}

#services p {
  color: white;
}

#services .card i {
  font-size: 40px;
  text-align: center;
}

#services .card h2 {
  font-size: 17px;
  margin-top: 1.5rem;
  text-align: center;
}

#resume {
  width: 100%;
  background-color: #080808;
}

#resume h5 {
  color: #1e99b9;
  padding-top: 50px;
  padding-bottom: 3px;
}

#resume h1 {
  color: white;
}

#resume h3 i {
  color: #1e99b9;
}

#resume h3 {
  padding-bottom: 3px;
  color: white;
  margin-top: 30px;
}

.hr {
  background-color: #1e99b9;
  width: 100%;
  height: 1px;
}

#resume h2 {
  color: white;
  font-size: 20px;
}

#portfolio {
  width: 100%;
  background-color: #060606;
}

#portfolio h1 {
  color: #1e99b9;
  padding-top: 15px;
}

#portfolio p {
  color: white;
  margin-top: 1.2rem;
}

#portfolio .card {
  transition: 0.5s ease;
  cursor: pointer;
  border: none;
}

#portfolio .card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 1px 25px rgba(245, 222, 179, 0.37);
}

#resume p {
  color: white;
  font-weight: bold;
  letter-spacing: 2px;
}

#resume i {
  color: #1e99b9;
  letter-spacing: 2px;
  line-height: 1.3rem;
}

#cardMargin3 {
  padding: 3rem;
}

#cardMargin04 {
  padding: 3rem;
}

#contact {
  width: 100%;
  background-color: #060606;
}

#contact h2 {
  color: white;
  padding-top: 20px;
}

#contact i {
  color: #1e99b9;
  margin-top: 30px;
  font-size: 25px;
  margin-left: 7px;
  margin-right: 7px;
}

#cardMargin05 {
  padding: 3rem;
}

#footer {
  width: 100%;
  background-color: #0a0a0a;
  padding: 1.5rem;
  color: white;
  font-size: 16px;
}

#footer1 {
  width: 100%;
  background-color: #185d6499;
  padding: 1.2rem;
  color: white;
  font-size: 25px;
}

#footer2 {
  width: 100%;
  padding: 1.2rem;
  color: white;
  font-size: 25px;
}

#footer3 {
  width: 100%;
  background-color: #8dffa887;
  padding: 1rem;
  font-size: 19px;
}

.bgMain {
  background: url(./profImage/bg.jpg);
  background-color: #29242fa1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;
  background-blend-mode: overlay;
}

.appsName {
  font-size: 24px;
  text-align: center;
  font-weight: bold;
}

.appsName:hover {
  text-decoration: none !important;
}

#portfolio i {
  font-size: 40px;
  text-align: center;
  color: #1e99b9 !important;
}

.paddingFix000 {
  padding-top: 25px;
  padding-bottom: 15px;
  border-radius: 5px;
  background: transparent;
  border: 1px solid #1e99b9;
  margin: 1rem;
  text-align: center;
  transition: 0.5s ease;
}

.paddingFix000:hover {
  transform: translateY(-10px);
  box-shadow: 0px 1px 25px rgba(245, 222, 179, 0.233);
}

.glow {

  text-shadow: 0 0 5px #1876e9, 0 0 10px #00d5ff, 0 0 15px #00a2ff, 0 0 25px #0095ff, 0 0 40px #009dff, 0 0 10px #00e5ff, 0 0 98px #00ddff;
  color: #fdfdfc;
  animation: blink 5s infinite;
  -webkit-animation: blink 5s infinite;
}

@-webkit-keyframes blink {

  20%,
  24%,
  55% {

    color: #111;
    text-shadow: none;
  }

  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {

    text-shadow: 0 0 5px #1888e9, 0 0 10px #35ccd2, 0 0 15px #00ffd5, 0 0 25px #00a2ff, 0 0 40px #009dff, 0 0 10px #0077ff, 0 0 98px #00b3ff;
    color: #fff6a9;
  }
}

@keyframes blink {

  20%,
  24%,
  55% {

    color: #111;
    text-shadow: none;
  }

  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {

    text-shadow: 0 0 5px #18c6e9, 0 0 10px #1d91cb, 0 0 15px #1faee7, 0 0 25px #1ea8ee, 0 0 40px #009dff, 0 0 10px #00f2ff, 0 0 98px #00ffff;
    color: #fafafa;
  }
}